import { CreatedUser } from '@/interfaces/api/general/user';
import { Department } from '@/interfaces/model/general/department';

export const getJoinedDepartmentName = (departments?: Department[]): string => {
  if (!departments) return '';
  return departments.map((d) => d.name).join(', ');
};

export const getFullName = (user?: CreatedUser, empty = '') => {
  if (!user) {
    return empty;
  }
  return [user.last_name, user.middle_name, user.first_name].join(' ');
};
